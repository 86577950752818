import React from 'react';
import styles from './UpdateLive.module.css';

const LiveSpinner = () => {
  return (
    <div className={styles.spinnerOverlay}>
      <div className={styles.spinner}></div>
      <p className={styles.text}>Live update in progress...</p>
    </div>
  );
};

export default LiveSpinner;
