import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import styles from './SportDetailPage.module.css'; // Import styles as an object

function SportDetailPage() {
  const location = useLocation();
  const { sport = { name: '', playerListUploaded: false } } = location.state || {};
  const collegeName = sessionStorage.getItem('collegeName');
  const [coachInfo, setCoachInfo] = useState({
    coachName: '',
    coachEmail: '',
    coachNumber: '',
    teamType: ''
  });
  const [playersFile, setPlayersFile] = useState(null);
  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

  const handleCoachChange = (e) => {
    const { name, value } = e.target;
    setCoachInfo({
      ...coachInfo,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setPlayersFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('collegeName', collegeName);
    formData.append('coachName', coachInfo.coachName);
    formData.append('coachEmail', coachInfo.coachEmail);
    formData.append('coachNumber', coachInfo.coachNumber);
    formData.append('teamType', coachInfo.teamType);
    formData.append('playersFile', playersFile);

    try {
      const response = await fetch(`${BACKEND_URL}/register-sport/${sport.name}`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        console.log('Registration successful:', result);
        navigate('/dashboard');
      } else {
        console.error('Registration failed:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>{sport.name.toUpperCase()} DETAILS</span>
        </div>
      </nav>
      <div className={styles.page}>
        <div className={styles.ribbon}>
          <div className={styles.ribbonText}>COACH REGISTRATION</div>
        </div>
        <div className={styles.formContainerCoach}>
          <form className={styles.coachRegistrationGrid} onSubmit={handleSubmit}>
            <h1 className={styles.sportName}>{sport.name}</h1>
            <div className={styles.genderInput}>
              <input
                type="radio"
                id="cis-men"
                name="teamType"
                value="CIS-MEN"
                checked={coachInfo.teamType === 'CIS-MEN'}
                onChange={handleCoachChange}
              />
              <label htmlFor="cis-men" className={styles.genderLabel}>CIS-MEN</label>

              <input
                type="radio"
                id="noncis-men"
                name="teamType"
                value="NONCIS-MEN"
                checked={coachInfo.teamType === 'NONCIS-MEN'}
                onChange={handleCoachChange}
              />
              <label htmlFor="noncis-men" className={styles.genderLabel}>NONCIS-MEN</label>
            </div>
            <input
              type="text"
              name="coachName"
              placeholder="Coach Name*"
              className={styles.input}
              value={coachInfo.coachName}
              onChange={handleCoachChange}
              required
            />
            <input
              type="email"
              name="coachEmail"
              placeholder="Coach Email*"
              className={styles.input}
              value={coachInfo.coachEmail}
              onChange={handleCoachChange}
              required
            />
            <input
              type="text"
              name="coachNumber"
              placeholder="Coach Phone Number*"
              className={styles.input}
              value={coachInfo.coachNumber}
              onChange={handleCoachChange}
              required
            />
            <input
              type="file"
              name="playersFile"
              className={styles.input}
              onChange={handleFileChange}
              required
            />
            <button type="submit" className={styles.submitButton}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SportDetailPage;
