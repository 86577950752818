
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import styles from './FantasyPage.module.css';
import defaultImage from "../assets/userimage.png"

function FantasyPage() {
  const navigate = useNavigate();
  const { gameName } = useParams();
  const [teamName, setTeamName] = useState('');
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [budget, setBudget] = useState("1000M");
  const [team, setTeam] = useState({});
  const [selectedBox, setSelectedBox] = useState(null);
  const [positionFilter, setPositionFilter] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL 

  const sports = [
    { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
    { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
    { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
    { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
    { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
    { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
    { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
    { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
    { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
    { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
    { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
    { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' }
  ];

  useEffect(() => {
    const fetchGameAndPlayers = async () => {
      try {
        const gameResponse = await fetch(`${BACKEND_URL}/games`, {
          credentials: 'include',
        });
        const games = await gameResponse.json();

        const game = games.find(g => g.name.toLowerCase() === gameName.toLowerCase());

        if (!game) {
          alert('Game not found');
          return;
        }
        const playerResponse = await fetch(`${BACKEND_URL}/fetch-players/${game.college}`, {
          credentials: 'include',
        });
        const players = await playerResponse.json();

        setPlayers(players);
        setFilteredPlayers(players);
        setBudget(`${game.budget}M`);
        setTeamName(game.name);

        // Initialize the team based on the fetched game's player limits
        const initialTeam = {};
        sports.forEach(sport => {
          const limit = game.playerLimits[sport.name] || 0;
          initialTeam[sport.name] = Array(limit).fill({ playerName: '', sport: sport.name, position: '', price: 0, gender: '' });
        });
        setTeam(initialTeam);

      } catch (error) {
        console.error('Error fetching game or players:', error);
        alert('An error occurred while fetching the game or players.');
      }
    };

    fetchGameAndPlayers();
  }, [gameName]);

  const handleAddPlayer = (player) => {
    if (!selectedBox) {
      alert('Select a box to add the player.');
      return;
    }

    const [sport, index] = selectedBox.split('-');

    const isDuplicate = Object.values(team).flat().some(p => p.playerName === player.playerName);
    if (isDuplicate) {
      alert('This player is already in your team.');
      return;
    }

    if (player.playerSport !== sport) {
      alert('Choose a player of the corresponding sport.');
      return;
    }

    const currentBudget = parseFloat(budget.replace("M", ""));
    const newBudget = currentBudget - player.playerPrice;
    if (newBudget < 0) {
      alert('Insufficient budget to add this player.');
      return;
    }

    setTeam(prevTeam => {
      const updatedSport = [...prevTeam[sport]];
      updatedSport[index] = player;
      return {
        ...prevTeam,
        [sport]: updatedSport
      };
    });
    setBudget(`${newBudget}M`);
  };

  const handleBoxClick = (sport, index) => {
    setSelectedBox(`${sport}-${index}`);
  };

  const renderTeamBoxes = () => {
    const allPlayers = Object.entries(team).flatMap(([sport, players]) =>
      players.map((player, index) => ({ ...player, sport, index }))
    );

    const totalPlayers = allPlayers.length;

    const getBoxClass = (index, type) => {
      let className = styles.teamBox;
      if (selectedBox === `${allPlayers[index].sport}-${allPlayers[index].index}`) {
        className += ` ${styles.selected}`;
      }
      if (allPlayers[index].playerName !== '') {
        className += ` ${styles.filled}`;
      }
      if (type) {
        className += ` ${styles[type]}`;
      }
      return className;
    };

  const renderBox = (index, type) => {
  const player = allPlayers[index];
  const isPlayerSelected = player.playerName !== '';

  return (
    <div 
      className={getBoxClass(index, type)} 
      onClick={() => handleBoxClick(player.sport, player.index)} 
      key={`${player.sport}-${player.index}`}
    >
      <div className={styles.playerInfoBox}>
        <div className={styles.playerImageNamePositionContainer}>
        <div className={styles.playerImageContainer}>
          {!isPlayerSelected &&           <span className={styles.plusSign}>+</span>        }
            <img 
              src={player.playerImage ? player.playerImage : defaultImage} 
              className={isPlayerSelected?styles.playerImage:styles.hidden} 
              alt="Player"
            />
        </div>
        <div className={styles.playerNamePositionContainerBox}>
          <span className={styles.playerNameBox}>{player.playerName}</span>
          <span className={styles.playerPositionBox}>{player.playerPosition}</span>
        </div>
        </div>
        <span className={styles.playerPriceBox}>
          {player.playerPrice > 0 ? `${player.playerPrice} MIL` : ''}
        </span>
      </div>
      <div className={styles.sportLabel}>{player.sport}</div>
    </div>
  );
};


    const layoutMapping = {
      3: ['longBox', 'longBox', 'longBox'],
      4: ['longBox', 'longBox', 'halfBox', 'halfBox'],
      5: ['longBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox'],
      6: ['longBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox'],
      7: ['longBox', 'longBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox'],
      8: ['longBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox'],
      9: ['longBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox'],
      11: ['longBox', 'longBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox'],
      12: ['longBox', 'longBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox'],
      13: ['longBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox'],
      14: ['longBox', 'longBox', 'halfBox', 'halfBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox', 'smallBox']
    };

    const layout = layoutMapping[totalPlayers] || [];

    return (
      <>
        {layout.map((type, index) => renderBox(index, type))}
      </>
    );
  };

  const handleFilterChange = () => {
    let filtered = players;

    if (positionFilter) {
      filtered = filtered.filter(player => player.playerPosition === positionFilter);
    }
    if (genderFilter) {
      filtered = filtered.filter(player => player.playerGender === genderFilter);
    }
    if (priceFilter) {
      const [minPrice, maxPrice] = priceFilter.split('-').map(Number);
      filtered = filtered.filter(player => player.playerPrice >= minPrice && player.playerPrice <= maxPrice);
    }

    setFilteredPlayers(filtered);
  };

  const uniquePositions = players.length>0?[...new Set(players.map(player => player.playerPosition))]:[];
  const uniqueGenders = players.length>0?[...new Set(players.map(player => player.playerGender))]:[];
  const priceRanges = Array.from({ length: 11 }, (_, i) => `${i * 10}-${(i + 1) * 10}`);

  useEffect(() => {
    handleFilterChange();
  }, [positionFilter, genderFilter, priceFilter, players]);

  const handleSubmit = async () => {
    if (!teamName) {
      alert('Please enter a team name.');
      return;
    }

    const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage

    try {
      const collegeName = sessionStorage.getItem('collegeName');
      const response = await fetch('http://localhost:5000/submit-fantasy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ teamName, team, collegeName, budget }),
        credentials: 'include',
      });

      if (response.ok) {
        alert('Fantasy team submitted successfully!');
        navigate('/some-next-page'); // Navigate to the next page after successful submission
      } else {
        const errorData = await response.json();
        alert('Failed to submit team: ' + errorData.error);
      }
    } catch (error) {
      console.error('Error submitting team:', error);
      alert('An error occurred while submitting your team.');
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.topNavbar1}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>{gameName}</span>
        </div>
      </div>
      <div className={styles.topNavbar2}>
        <a href="#">Profile</a>
        <a href="#">Dashboard</a>
        <a href="#">Tournaments</a>
        <a href="#">Fantasy</a>
        <a href="#">Stats</a>
      </div>
      <div className={styles.fantasyPage}>
        <div className={styles.teamContainer}>
          <div className={styles.teamGrid}>
            {renderTeamBoxes()}
          </div>
          <div className={styles.buttons}>
          <button className={styles.resetButton}>Reset</button>
          <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
          </div>
        </div>
        <div className={styles.flexColumn} style={{ width: "25vw", height: "90vh" }}>
          <div className={styles.playerSelectionContainer} style={{ width: "25vw", height: "100%", padding:"20px" }}>
            <div className={styles.playerSelection}>
              <div className={styles.moneyLeftContainer}>
                <h1 className={styles.moneyLeft}>Money<br /> Left</h1>
                <h1 className={styles.moneyPrice}>{budget}</h1>
              </div>
              <select className={styles.filterDropdown} onChange={(e) => setPositionFilter(e.target.value)}>
                <option value="">Position</option>
                {uniquePositions.map((position, index) => (
                  <option key={index} value={position}>{position}</option>
                ))}
              </select>
              <select className={styles.filterDropdown} onChange={(e) => setGenderFilter(e.target.value)}>
                <option value="">Gender</option>
                {uniqueGenders.map((gender, index) => (
                  <option key={index} value={gender}>{gender}</option>
                ))}
              </select>
              <select className={styles.filterDropdown} onChange={(e) => setPriceFilter(e.target.value)}>
                <option value="">Price</option>
                {priceRanges.map((range, index) => (
                  <option key={index} value={range}>{range} MIL</option>
                ))}
              </select>
              <div className={styles.playerList}>
                {filteredPlayers.length > 0 && filteredPlayers.map((player, index) => (
                  <div key={index} className={styles.playerItem} onClick={() => handleAddPlayer(player)}>
                    <div className={styles.playerNamePositionContainer}>
                      <span className={styles.playerPlayerName}>{player.playerName}</span>
                      <span className={styles.playerPlayerPosition}>{player.playerPosition}</span>
                    </div>
                    <div className={styles.playerPlayerPriceContainer}>
                      <span className={styles.playerPlayerPrice}>{player.playerPrice > 0 ? `${player.playerPrice} MIL` : ''}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FantasyPage;
