import React, { useState, useEffect } from 'react';
import styles from './AdminVerification.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';

function AdminVerification() {
  const [college, setCollege] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [userCode, setUserCode] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const [message, setMessage] = useState('');
  const [verificationCodes, setVerificationCodes] = useState([]);
  const [editingCode, setEditingCode] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [teamName, setTeamName] = useState(''); // New state for team name
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchVerificationCodes();
    fetchTournaments();
  }, []);


  const addTeam = async (teamData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', 
        body: JSON.stringify(teamData),
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
        setTeamName('');
      } else {
        console.error('Failed to add team');
      }
    } catch (error) {
      console.error('Error adding team:', error);
    }
  };

  const updateTeam = async (teamId, updatedData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team/${teamId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
        credentials: 'include', 
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
      } else {
        console.error('Failed to update team');
      }
    } catch (error) {
      console.error('Error updating team:', error);
    }
  };

  const deleteTeam = async (teamId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team/${teamId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
      } else {
        console.error('Failed to delete team');
      }
    } catch (error) {
      console.error('Error deleting team:', error);
    }
  };

  const fetchTeams = async (tournamentName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${tournamentName}/teams`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setTeams(data);
        setSelectedTournament(tournamentName);
      } else {
        console.error('Failed to fetch teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };


  const addPlayer = async (playerData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/player`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(playerData),
        credentials: 'include',
      });
      if (response.ok) {
        fetchPlayers(selectedTournament); // Refresh player list
        setFullName('');
        setEmail('');
      } else {
        console.error('Failed to add player');
      }
    } catch (error) {
      console.error('Error adding player:', error);
    }
  };

  const updatePlayer = async (playerId, updatedData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/player/${playerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
        credentials: 'include',
      });
      if (response.ok) {
        fetchPlayers(selectedTournament); // Refresh player list
      } else {
        console.error('Failed to update player');
      }
    } catch (error) {
      console.error('Error updating player:', error);
    }
  };

  const deletePlayer = async (playerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/player/${playerId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchPlayers(selectedTournament); // Refresh player list
      } else {
        console.error('Failed to delete player');
      }
    } catch (error) {
      console.error('Error deleting player:', error);
    }
  };

  const fetchTournaments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournaments`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setTournaments(data);
      } else {
        console.error('Failed to fetch tournaments');
      }
    } catch (error) {
      console.error('Error fetching tournaments:', error);
    }
  };

  const fetchPlayers = async (tournamentName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${tournamentName}/players`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setPlayers(data);
        setSelectedTournament(tournamentName);
      } else {
        console.error('Failed to fetch players');
      }
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };

  const fetchVerificationCodes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        setVerificationCodes(data);
      } else {
        console.error('Failed to fetch verification codes');
      }
    } catch (error) {
      console.error('Error fetching verification codes:', error);
    }
  };
  // Function to handle updating a verification code or player
const handleUpdate = async (e) => {
  e.preventDefault();
  if (editingCode) {
    if (selectedTournament) {
      // Update player if a tournament is selected
      await updatePlayer(editingCode, { fullName, email });
    } else {
      // Update verification code if no tournament is selected
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes/${editingCode}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ college, adminCode, userCode, tournamentName }),
        });
        if (response.ok) {
          setMessage('Verification code updated successfully');
          fetchVerificationCodes();
          setEditingCode(null);
          setCollege('');
          setAdminCode('');
          setUserCode('');
          setTournamentName('');
        } else {
          console.error('Failed to update verification code');
        }
      } catch (error) {
        console.error('Error updating verification code:', error);
      }
    }
  }
};

// Function to set edit mode for players or verification codes
const handleEdit = (item) => {
  if (item.adminCode) {
    // Editing a verification code
    setEditingCode(item._id);
    setCollege(item.college);
    setAdminCode(item.adminCode);
    setUserCode(item.userCode);
    setTournamentName(item.tournamentName);
    setSelectedTournament(null); // Ensure no tournament is selected
  } else {
    // Editing a player
    setEditingCode(item._id);
    setFullName(item.fullName);
    setEmail(item.email);
    setSelectedTournament(selectedTournament);
  }
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ college, adminCode, userCode, tournamentName }),
        credentials: 'include',
      });
      if (response.ok) {
        setMessage('Verification code entry added successfully');
        fetchVerificationCodes();
      } else {
        setMessage('Failed to add verification code entry');
      }
    } catch (error) {
      console.error('Error adding verification code entry:', error);
      setMessage('An error occurred while adding the entry');
    }
  };

  return (
    <div className={styles.adminPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Admin Verification</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2>Verification Code Form</h2>
          <form className={styles.form} onSubmit={editingCode ? handleUpdate : handleSubmit}>
            <input
              type="text"
              placeholder="College"
              value={college}
              onChange={(e) => setCollege(e.target.value)}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              placeholder="Admin Code"
              value={adminCode}
              onChange={(e) => setAdminCode(e.target.value)}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              placeholder="User Code"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
              required
              className={styles.inputField}
            />
            <input
              type="text"
              placeholder="Tournament Name"
              value={tournamentName}
              onChange={(e) => setTournamentName(e.target.value)}
              required
              className={styles.inputField}
            />
            <button type="submit" className={styles.submitButton}>
              {editingCode ? 'Update Verification Code' : 'Add Verification Code'}
            </button>
          </form>
        </div>
        <div className={styles.verificationList}>
          <h2>Verification Codes</h2>
          <ul>
            {verificationCodes.map((code) => (
              <li key={code._id} className={styles.verificationItem}>
                <span>{code.college} - {code.tournamentName}</span>
                <div>
                  <button onClick={() => handleEdit(code)} className={styles.editButton}>Edit</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <h2 className={styles.sectionTitle}>Select a Tournament</h2>
<ul className={styles.tournamentList}>
  {tournaments.map((tournament) => (
    <li key={tournament._id} className={styles.tournamentItem}>
      <span className={styles.tournamentName}>{tournament.tournamentName}</span>
              <button onClick={() => { fetchPlayers(tournament.tournamentName); fetchTeams(tournament.tournamentName); }} className={styles.viewPlayersButton}>View Players & Teams</button>
    </li>
  ))}
</ul>
{selectedTournament && (
          <div>
            <h2>Players in {selectedTournament}</h2>
            <ul className={styles.playerList}>
            {players.map((player) => (
              <li key={player._id}>
                {player.fullName} - {player.email}
                <button className={styles.editButton} onClick={() => handleEdit(player)}>Edit</button>
                <button className={styles.deleteButton} onClick={() => deletePlayer(player._id)}>Delete</button>
              </li>
            ))}
          </ul>
            
            <h2>Teams in {selectedTournament}</h2>
            <ul className={styles.teamList}>
              {teams.map((team) => (
                <li key={team._id}>
                  <span>{team["Team Name"]}</span>
                  <button onClick={() => handleEdit(team, 'team')} className={styles.editButton}>Edit</button>
                  <button onClick={() => deleteTeam(team._id)} className={styles.deleteButton}>Delete</button>
                </li>
              ))}
            </ul>
            <form className={styles.form} onSubmit={(e) => {
              e.preventDefault();
              editingCode ? updateTeam(editingCode, { teamName }) : addTeam({ teamName });
            }}>
              <input
                type="text"
                placeholder="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                required
                className={styles.inputField}
              />
              <button type="submit" className={styles.submitButton}>
                {editingCode ? 'Update Team' : 'Add Team'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminVerification;
