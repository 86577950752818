import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import SportsSelectionPage from './components/SportsSelectionPage';
import SportsDashboardPage from './components/SportsDashboardPage';
import SportDetailPage from './components/SportDetailPage';
import FantasyPage from './components/FantasyPage';
import AdminDashboard from './components/AdminDashboard';
import PrivateRoute from './components/PrivateRoute';
import CreateTournament from './components/CreateTournament';
import TournamentDashboard from './components/TournamentDashboard';
import TournamentPage from './components/TournamentPage';
import UserSignupPage from './components/UserSignupPage';
import ViewTournamentsPage from './components/ViewTournamentsPage';
import LiveBudgetSheet from './components/LiveBudgetSheet';
import AuctionPage from './components/AuctionPage';
import AdminVerification from './components/AdminVerification';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeamList from './components/TeamList';

function App() {
  return (
    <Router>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1' }}> 
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/usersignup" element={<UserSignupPage />} />
            <Route path="/dashboard" element={<PrivateRoute element={TournamentDashboard} />} />
            <Route path="/fantasy/:gameName" element={<PrivateRoute element={FantasyPage} />} />
            <Route path="/create-tournament" element={<PrivateRoute element={CreateTournament} />} />
            <Route path="/tournament/:tournamentName" element={<TournamentPage />} />
            <Route path="/tournaments/:tournamentName" element={<TournamentPage />} />
            <Route path="/tournaments" element={<ViewTournamentsPage />} />
            <Route path="/livebudgetsheet/:tournamentName" element={<LiveBudgetSheet />} />
            <Route path="/teamlist/:tournamentName" element={<TeamList />} />
            <Route path="/auction" element={<AuctionPage />} />
            <Route path="/admin" element={<PrivateRoute element={AdminVerification} />} /> {/* Updated route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
